<template>
	<div class="box">
		<div class="row">
			<div class="col-12">
				<h2>{{ $t('pax.title_criteres') }}</h2>

				<LoadingSpinner v-if="processing" />
				<template v-else>
					<div v-for="critere in all_criteres" :key="critere.criteres_id" class="mb-3 px-3 py-2 border rounded">
						<div class="row align-items-center">
							<div class="col">
								<b>{{ critere.criteres_code }}</b>
							</div>
							<div class="col-auto">
		        				<button class="btn btn-primary" @click="openModalEdit(critere)">{{ $t('global.modifier') }} <font-awesome-icon :icon="['fal', 'angle-right']" /></button>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>

		<b-modal size="xl" ref="modal-critere" hide-footer>
            <template v-slot:modal-title>
                {{ $t("pax.edit_critere") }} {{ critere_edit.critere_code }}
            </template>
            <div v-if="critere_edit" class="row justify-content-around px-3">
            	<b-tabs content-class="mt-3">
					<b-tab
						v-for="locale in locales"
						:key="locale.code"
						:title="locale.title"
						:active="locale.code === localeSelected.code"
						title-link-class="border"
					>
						<div class="row">
							<div class="col-12">
								<label>{{ $t('pax.libelle') }}</label>
								<b-input type="text" class="form-control" v-model="critere_edit.lang[locale.code].critereslang_label"/>
							</div>
							<div class="col-6">
								<label>{{ $t('pax.moins') }}</label>
								<b-input type="text" class="form-control" v-model="critere_edit.lang[locale.code].critereslang_moins"/>
							</div>
							<div class="col-6">
								<label>{{ $t('pax.plus') }}</label>
								<b-input type="text" class="form-control" v-model="critere_edit.lang[locale.code].critereslang_plus"/>
							</div>
							<div class="col-12">
								<label>{{ $t('pax.description') }}</label>
								<e-editor-froala
					                :placeholder="getTrad('pax.criteres_description')"
					                :content="critere_edit.lang[locale.code].critereslang_description"
					                @change="updateDescription($event, locale.code)"
					            ></e-editor-froala>
					        </div>
					        <div class="col-12 mt-2 text-center">
					        	<button class="btn btn-primary" @click="saveCritereLang(locale.code)">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing_critere" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></button>
					        </div>
				        </div>
					</b-tab>
				</b-tabs>
            </div>
        </b-modal>
	</div>
</template>

<script type="text/javascript">
	import PaxMixin from "@/mixins/Pax.js"

	export default {
		name: 'PAXCriteres',
		mixins: [PaxMixin],
		data () {
			return {
				processing: false,
				all_criteres: [],
				critere_edit: null,
				locales: [],
				localeSelected: {
					title: this.$t('global.lang_fr'),
					code: 'fr'
				},
				processing_critere: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.processing = true
				const criteres = await this.getCriteres()
				this.all_criteres = []
				criteres.forEach(critere => {
					this.all_criteres.push({
						criteres_code: critere.criteres_code,
						criteres_id: critere.criteres_id,
						lang: {
							'fr': critere.criteres_lang.find(lang => lang.critereslang_lang == 'fr'),
							'en': critere.criteres_lang.find(lang => lang.critereslang_lang == 'en'),
							'es': critere.criteres_lang.find(lang => lang.critereslang_lang == 'es')
						}
					})
				})
				this.processing = false

				this.locales = this.getLocales()
			},

			openModalEdit(critere) {
				this.critere_edit = critere
				this.$refs['modal-critere'].show()
			},

			updateDescription(e, lang) {
				this.critere_edit.lang[lang].critereslang_description = e.val
			},

			async saveCritereLang(lang) {
				this.processing_critere = true
				const params = {
					label: this.critere_edit.lang[lang].critereslang_label,
					moins: this.critere_edit.lang[lang].critereslang_moins,
					plus: this.critere_edit.lang[lang].critereslang_plus,
					description: this.critere_edit.lang[lang].critereslang_description
				}
				await this.saveCritereByLang(this.critere_edit.criteres_id, lang, params)
				this.processing_critere = false
				this.successToast('toast.info_save_succes')
			}
		},

		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>